async function initializeViewer(access_token, urn) {
    const options = {
        env: 'AutodeskProduction',
        getAccessToken: (onGetAccessToken) => {
            onGetAccessToken(access_token, 3599);
        }
    };
    

    Autodesk.Viewing.Initializer(options, function onInitialized() {
        const viewerDiv = document.getElementById('forgeViewer');
        const viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv);
        viewer.start();
        
        const documentId = 'urn:' + urn;
        Autodesk.Viewing.Document.load(documentId, function (doc) {
            const viewables = doc.getRoot().getDefaultGeometry();
            viewer.loadDocumentNode(doc, viewables).then(function () {
                viewer.setViewCube('front');
            });
        });
          viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, function (event) {
            const dbIdArray = event.dbIdArray;
            if (dbIdArray.length > 0) {
                console.log('Clicked on element with ID:', dbIdArray[0]);
                alert('Clicked on element with ID:'+ dbIdArray[0]);
            } else {
                console.log('Clicked on empty space');
            }
        });
    });
}
